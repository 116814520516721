import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2870615501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FormProps } from '../../components/FormProps';
export const name = 'AgentComponentForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{name}</h2>
    <FormProps fields={[{
      name: 'slug',
      type: 'text'
    }, {
      name: 'secret',
      type: 'password'
    }, {
      name: 'changeSecret',
      type: 'checkbox'
    }, {
      name: 'url',
      type: 'text'
    }]} formOptions={[{
      name: 'slug',
      type: 'string',
      required: true,
      description: 'The slug for the agent to be updated/created.'
    }]} dataSources={[{
      name: 'agent',
      type: 'agent'
    }]} mdxType="FormProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      